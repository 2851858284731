.more-info {
    margin-top: 140px;
}

.left-image {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-size: cover;
}

.more-info .left-image img {
    width: 90%;
    overflow: hidden;
    height: 330px;

}

.more-info .more-info-content {
    background-color: #003399;
    color: white;
}

.about-info .more-info-content {
    background-color: #003399;
}

.about-info .right-content {
    padding: 0px !important;
    margin-right: 30px;

}

.more-info .right-content {
    padding: 45px 45px 45px 30px;
}

.more-info .right-content span {
    text-transform: uppercase;
    font-size: 15px;
    color: #666;
    letter-spacing: 1px;
    margin-bottom: 10px;
    display: block;
}

.more-info .right-content h2 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 35px;
}

.more-info .right-content em {
    font-style: normal;
    color: #5339c6;
}

.more-info .right-content p {
    margin-bottom: 30px;
}


.page-heading-about {
    text-align: center;
    background-image: url(../../assets/images/about-top3.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 230px 0px 150px 0px;
    color: #fdfbff;
}

.page-heading h1 {
    text-transform: capitalize;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 2px;
    margin-bottom: 18px;
    
}

.page-heading span {
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;
    display: block;
}


/* team */

.team {
    background-color: #f7f7f7;
    margin-top: 140px;
    padding: 120px 0px;
}

.team-item img {
    width: 100%;
    overflow: hidden;
}

.team-item .down-content {
    background-color: #003399;
    padding: 30px;
    color: white;
}

.team-item .down-content h4 {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.25px;
    margin-bottom: 10px;
}

.team-item .down-content span {
    color: orange;
    font-weight: 600;
    font-size: 14px;
    display: block;
    margin-bottom: 15px;
}

.team-item .down-content p {
    margin-bottom: 20px;
    color: white;
}

.section-heading {
    text-align: center;
    margin-bottom: 80px;
}

.section-heading h2 {
    font-size: 36px;
    font-weight: 600;
    color: #1e1e1e;
}

.section-heading em {
    font-style: normal;
    color: #5339c6;
}

.section-heading span {
    display: block;
    margin-top: 15px;
    text-transform: uppercase;
    font-size: 15px;
    color: #666;
    letter-spacing: 1px;
}

@media (max-width: 768px) {
    .team-item{
        margin-top: 10px;

    }
    
}