
/* topbar starts */ 
    
.topbar{
    background-color: #003566;
}

.topbar-right {
    position: relative;
    background: #FF5E14;
}

.topbar-right::before {
    position: absolute;
    content: "";
    width: 30px;
    height: 100%;
    top: 0;
    left: -15px;
    transform: skewX(-30deg);
    background-color:#FF5E14;

}

/* topbar ends */