
/* navbar styling starts */

.navbar{
    background-color: #2529e2;
    
}
#navSiteName{
    display: flex;
    flex-direction: column;
    color: white;
    line-height: 1em;
    font-size: 1.2rem;
    margin-left: 0.7rem;
}
.site-logo-container{
    display: flex;
    align-items: center;
    padding-left: 20px;
  }

#navbarLogo{
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    background: url(../../assets/images/logo.jpeg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.nav-link:hover{
    border-bottom: 1px solid white;
    border-radius: 2px;
}

#login-button{
    border-radius: 1.2rem;
    width: 4rem;
    padding: 0.4rem;
    background-color: #0509e7;
    box-shadow: 0 2px 4px rgb(2, 2, 153);    
    transition: ease-in-out 0.3ms;
    border: none;
}
#login-button:hover{
    box-shadow: 2px 4px 8px rgb(0, 0, 71);    
}


/* navbar styling ends */