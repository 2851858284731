footer {
    background-color: #003399;
    color: #fff;
}

.footerLogo{
    background: url(../../assets/images/Br.png);
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.social-icons{
    background-color: #fff;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    text-align: center;
    transition: all 0.3s;
}

.social-icons:hover{
    background-color: #7e8bff;
    color: white;
}

.quick-links:hover{
 letter-spacing: 1px;
}