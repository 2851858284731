.page-heading-contact {
    text-align: center;
    background-image: url(../../assets/images/contact.jpeg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 230px 0px 150px 0px;
    color: #fdfbff;
}

.contact-information {
    margin-top: 10px;
    margin-bottom: 10px;

}

.contact-information .contact-item {
    padding: 60px 30px;
    background-color: #003399;
    text-align: center;
    height: 330px;

}

.contact-information .contact-item i {
    color: white;
    font-size: 48px;
    margin-bottom: 40px;
}

.contact-information .contact-item h4 {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.25px;
    margin-bottom: 15px;
    color:orange;
}

.contact-information .contact-item p {
    margin-bottom: 20px;
    color:white
}

.contact-information .contact-item a {
    font-weight: 600;
    color: white;
    font-size: 15px;
}

.contact-form {
    background-color: #f7f7f7;

}

.contact-form h2 {
    text-align: center;
    color: #5339c6;
}