*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    background-color: rgb(231, 231, 231);
}




/* .home-intro-section styling starts */

.home-intro-section {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 1rem;
    height: 60vh; 
    max-height: 600px;
    background-color: black;
    padding: 1rem;
}

.intro-text {
    font-size: 6vw;
    color: white;
    text-align: center;
}
.fadeIn-img{
    animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

.intro-image {
    width: 100%;
    max-width: 80%;
    height: 40vh;
    border-radius: 60px;
    background: url(../../assets/images/photorealistic-money-with-plant.jpg) center/cover no-repeat;
    margin: 0 auto;
}

@media (min-width: 768px) {
    .home-intro-section {
        grid-template-columns: 1fr 1fr;
        align-items: center;
    }

    .intro-text {
        text-align: left;
    }

    .intro-image {
        height: 20.5rem; 
        max-height: 100%;
        margin-top: 0;
    }
}


/* .home-intro-section styling ends */



/* services section start  */

.services {
    margin-top: 140px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    overflow-x: hidden;
}



.card-body {
    text-align: center;
    color: white;
    background-color: #003399;
}
.service-card-body li{
    margin-top: 10px;
}
.service-card-body a{
    width: 100%;
}
.service-header .fixeddeposit-icon{
    font-size: 35px;
}
.service-row {
    text-align: center;
    padding-bottom: 30px;
  }
  
  .service-row h2 {
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    font-family: "Poppins", sans-serif;
    color: #213b52;
  }
  
  .service-row h2::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }
  
  .service-row h2::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: blue;
    bottom: 0;
    left: calc(50% - 20px);
  }

  .loan{
    height: 350px;
}
@media (max-width: 320px) {
    .loan{
        height: 360px;
    }
    .fix-deposit-tagline{
        margin: 0 !important;
    }
}
/* services section end  */

/* why choose us section starts */
.why-choose-us-heading {
    text-align: center;
    padding-bottom: 30px;
  }
  
  .why-choose-us-heading h2 {
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    font-family: "Poppins", sans-serif;
    color: #f6f9fc;
  }
  
  .why-choose-us-heading h2::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }
  
  .why-choose-us-heading h2::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #f1f1ef;
    bottom: 0;
    left: calc(50% - 20px);
  }
  
.why-choose-us p {
    margin-bottom: 40px;
    margin-top: 40px;
    justify-content: center;
    color: rgba(245, 243, 243, 0.959);
}

.why-choose-us {
    background-color: #003399;
    color: rgba(252, 251, 251, 0.918);
    text-align: center;
    padding-top: 56px;
}

.why-choose-us h2 {
    padding-top: 20px;
}
.why-choose-us-card-body span{
    font-size: 40px;
 margin-right: 3px;
}
.why-choose-us-card-body h5{
    margin-top: 9px;
}
.why-choose-us-card-body p{
    margin-top: 9px;
}
.counters {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding-top: 30px;
    color: white;

}

.counter {
    background-color: #003399;
    padding: 20px;
    border-radius: 0px;
    width: 200px;
    height: 150px;
    margin-bottom: 9px;
    border-right: 2px solid white;
    margin-top: 20px;
}

.counter .count {
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
}

.counter p {
    margin: 10px 0 0;
    color: white;
    font-size: 19px;
}
/* why choose us section ends */


/* testimonial section starts */

/* copied css starts */
.what-they-say-heading {
    text-align: center;
    padding-bottom: 30px;
  }
  
  .what-they-say-heading h2 {
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    font-family: "Poppins", sans-serif;
    color: #213b52;
  }
  
  .what-they-say-heading h2::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }
  
  .what-they-say-heading h2::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: blue;
    bottom: 0;
    left: calc(50% - 20px);
  }


  
/* ======= HELPLINE ======== */
.helpline{
    border: 10px solid blue;
}
.helpline-img{
    width: 100%;
    height: 200px;
    margin-bottom: 7px;
    background: url(../../assets/images/helpline.jpg) center/cover no-repeat;
}

.helpline-card-center {
    flex-direction: column;
}

.helpline-card-center span {
    display: block;
    margin: 25px;
    padding-left: 4rem;
}
.helpline-card-center .d-flex {
    
    padding-left: 8rem;
}
.helpline-card-center span b {
    color: blue;

}

.helpline-card-center hr {
    background-color: #3c0edf;
    height: 3px;
    color: black;
    width: 90%;
}

.helpline-card-center b {
    font-size: 20px;
}

.helpline-icon {
    display: flex;
    justify-content: end;
}
.helplineicon1{
    width: 120px;
    height: 34px;
    margin-right: 10px;
    background: url(../../assets/images/help1.jpeg);
    background-size: cover;
    
}
.helplineicon2{
    width: 6rem;
    height: 2.2rem;
    margin-left: 10px;
    margin-right: 10px;
    background: url(../../assets/images/help2.jpeg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
}

@media (max-width: 767.98px) {
    .order-sm-1 {
        order: 1;
        display: flex;
        height: 120px;
        text-align: center;
        justify-content: center;
        align-items: center;
    }
    .finance-span  {
     text-align: justify;
    }
    .why-choose-us p {
        text-align: justify;
    }

    .order-sm-2 {
        order: 3;
    }

    .order-sm-3 {
        order: 2;
    }

    .helpline-card-center span {
        display: block;
        margin: 25px;
        padding-left: 0rem;
        
        padding-top: 20px;
    }

    .helpline-card-center hr {
        background-color: #3c0edf;
        height: 3px;
        color: black;
        width: 100%;

    }
    .helpline-card-center .d-flex {
    
        padding-left: 0rem;
    }

    .helpline-icon {
        display: flex;
        justify-content: center;
    }
    .helpline-img{
        width: 100%;
        height: 145px;
        margin-bottom: 7px;
        background: url(../../assets/images/helpline.jpg) ;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}
