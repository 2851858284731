*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.login-body{
    height: 100vh;
    background: radial-gradient(ellipse,
    #0103DC 0%,
    #010266 100%
    );
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-container {
    width: 90%; 
    max-width: 800px; 
    height: 70vh;
    background: linear-gradient(#5C6BFA 0%, #030C54 100%);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: grid;
    grid-template-columns: 1fr; 
}
.info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}
.info > h4{
    margin-top: 10px;
}
.company-logo{
    height: 8rem;
    width: 8rem;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.login-form{
    background-color: white;
    margin: auto;
    width: 230px;
    max-width: 300px;
    height: auto;
    border-radius: 6px;
}

.login-form-label{
    font-weight: 700;
}

.forgot-password{
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    color: red;
    float: right;
}

.login-button-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}
.login-button{
    background-color: #0538BC;
    color: white;
    width: 100px;
    height: 28px;
    border: none;
    border-radius: 10px;
}
.login-button:hover{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}



@media only screen and (min-width: 768px) {
    .login-container {
        grid-template-columns: 1fr 1fr; 
    }
}
@media only screen and (max-width: 600px) {
    .login-container{
        height: 90%;
    }
    .commpany-logo{
        height: 3.7rem;
        width: 3.7rem;
    }
    .info > h4{
        font-size: 13px;
    }
    .info > h2{
        font-size: 17px;
    }
    .login-form{
        height: 90%;
    }
}
